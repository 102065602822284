<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="高校后勤人事" @moTitltFn="navPanel"></MoTitle>
    <div class="moXueYuan">
      <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">高校后勤人事</h1>
          <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">应用框架</h2>
        <span class="spanDesc"></span>
        <img src="@/assets/solute/SmartSchoolLoHr/gnt.jpg" alt="" style="width:100%;margin:30px 0">
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">业务痛点</h2>
        <div class="moValueTopText">
          根据《教育部关于深化高校后勤社会化改革的若干意见》，建立“市场提供服务、学校自主选择、政府宏观调控、行业自律管理、部门依法监督”比较完善的新型高校后勤保障体系，实现高校后勤服务的社会化、市场化、专业化、现代化，具有重要而深远的意义。目前高等院校后勤人事业务管理模式效能遇到了挑战：
        </div>
        <span class="spanDesc"></span>
        <div class="moValueContent">
          <div v-for="(item,index) in yetdData" :key="index" class="moValueItem">
            <div class="moValueTitle">{{item.title}}</div>
            <div class="moValueLine"></div>
            <div class="moValueText">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">解决方案</h2>
        <div class="moValueTopText">
          高校人力资源在自身业务发展需求和技术力量的推动下，已经到了由量变到质变的新阶段。新时代高校人力资源转型的最大特点就是实现人力资源价值最大化和人力资源业务数字化。
        </div>
        <span class="spanDesc"></span>
        <div class="moValueContent">
          <div v-for="(item,index) in soliteData" :key="index" class="moValueItem">
            <div class="moValueTitle">{{item.title}}</div>
            <div class="moValueLine"></div>
            <div class="moValueText">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="contentBgColor center">
          <h2 class="h2Desc">应用案例</h2>
          <span class="spanDesc"></span>
          <div class="moCase">
            <MoSwiper :list="caseData"></MoSwiper>
          </div>
      </div>
    </div>
    <MoBottom></MoBottom>
      <MoFoot></MoFoot>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import MoSwiper from '@/components/moswiper'
export default {
  name: 'moSmartSchoolLoHr',
  components: {
    MoTitle,
    MoSwiper
  },
  data () {
    return {
      isScroll: false,
      yetdData: [
        { title: '人员管理分散', content: '高校后勤人员类型多样、流动性大，难以形成集中统一管理。因信息分散，统计滞后，往往造成数据的缺失、时效性差，阻碍人才管控。' },
        { title: '员工服务满意度低', content: '后勤人事管理包括入职、离职、考勤、薪资等业务的全面管理，事务繁琐，牵涉面广，不利于员工个人价值发挥，缺乏归属感和认同感，员工满意度底。' },
        { title: '领导决策缺乏依据', content: '由于后勤人事数据多而分散，分析模型受限，无法支撑全面的人才盘点，辅助后勤人力资源规划，优化人才配置，严重制约了人力资源工作价值的体现，阻碍了后勤组织绩效提升和战略目标的达成。' },
        { title: '数据安全意识淡薄', content: '人事数据中包含了大量敏感的员工个人信息和财务数据，需要确保高度的数据安全性，而薪酬计算、信息统计仍然采用传统报表模式，无法有效保护重要数据的隐私。' }
      ],
      soliteData: [
        { title: '统一数据标准，优化人员结构', content: '搭建后勤人员信息数据平台，统一人力资源数据的管理口径，实现数据集中、分工管理，并对后勤人员结构合理分类，科学制定人员管理体系，优化人员结构，加快人才队伍建设，激活人才活力。' },
        { title: '移动应用平台，加强员工参与', content: '建设手机移动应用，员工可维护个人信息、查看工资条、进行业务申请等，突破了时间、空间、网络的限制，提高了员工的参与度和满意度。' },
        { title: '智能决策分析，助力领导决策', content: '通过对后勤人力资源管理信息的全局把控，快速准确地完成后勤人力资源状况的静态结构分析和动态趋势分析，掌握人才结构情况、变化调整情况，为后勤管理合理配置人才，为人才的培养和发展提供详实、客观的数据依据。' },
        { title: '全面系统集成，实现数据共享', content: '提供智能预警功能，将各种管理风险设置为灵活的预警内容，提前规避风险；同时预警与业务联动，让后勤人力工作由被动变主动。后勤人事系统与数据中心平台、OA、财务等系统进行集成应用，实现数据的互联互通，数据共享。' }
      ],
      caseData: [
        { src: require('@/assets/TypicalCase/typeCase/xadzkj.png'), title: '西安电子科技大学', p: '西安电子科技大学于2020年开始建设后勤人力资源管理系统，基本实现了外聘人员进校、在校、离校的全生命周期业务管理，包括职位变动、奖惩情况、学习经历、工作经历、培训经历、工资变动和发放、考核情况、调动情况、工作量等等。系统功能亮点突出，提供强大的工资分析功能，可按部门、岗位、级别、档次等分析各类工资项目的总额、比例、平均值、最高值、最低值等；提供历史工资分析功能，能以多种形式进行工资增幅的比较分析。通过后勤人力资源管理系统的建设，进一步提高人事管理工作效率，提高管理精细化水平，提高工作效率，实现人事管理工作的规范化、系统化、流程化，为后勤发展提供基于信息的决策支持。' },
        { src: require('@/assets/TypicalCase/typeCase/hdsf.png'), title: '华东师范大学', p: '华东师范大学后勤人力资源管理与服务平台的建设，是在“智慧校园”建设的整体规划下，采取分期建设、持续优化的策略，于2018年开始建设，已完成机构信息管理、职工基本信息库、人事管理、考勤管理、薪酬福利、预警提醒等模块，将学校后勤人事管理业务流程化、规范化、秩序化，建立了后勤人事信息化标准，梳理和优化了后勤人事管理和服务流程，提升了后勤人事管理的效率和服务水平。为避免信息孤岛，将后勤人力资源管理与服务平台和学校数字化校园平台及相关业务系统进行整合集成，包括身份认证集成、信息门户集成、业务数据集成等，实现数据共享，互融互通。' },
        { src: require('@/assets/solute/SmartSchoolLoHr/chengdu.png'), title: '成都大学', p: '成都大学后勤外聘员工信息化管理系统是以组织机构管理、人员信息管理、人员异动管理、合同管理、请假管理、工资管理等核心业务系统为基础，实现为二级单位、人力资源部、后勤处领导等不同的角色提供相关服务。二级单位可以通过平台对所属员工信息进行查询统计和分析，配合人力资源办公室进行员工入职、离职、请假、工资等管理工作。后勤处人力资源部通过系统统筹规划和组织实施后勤处外聘员工管理工作。后勤处领导可以实时掌握后勤处人事的现状和发展趋势，实现对后勤处用人战略计划的目标监控和过程指导。' }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/solution/smartSchoolLoHr')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/solute/smartInstitute/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.moXueYuan{
  padding-top: 2.75rem;
}
.moCase{
  width: 100%;
  margin-top: 1.875rem;
}
.designIdea{
  margin: 20px 0;
  font-size: .9375rem;
line-height: 1.5rem;

}
.knowMoreBtn{
  width: 120px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 30px;
  border: 1px solid rgba(211,218,228,0.5);
  color: #444444;
  font-size: 14px;
}

.moValueTopText{
  text-align:center;
  margin-top:15px;
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  line-height: 22px;
}
.moValueContent{
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.moValueItem{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid rgba(220,223,230,.5);
  box-sizing: border-box;
}
.moValueTitle{
  font-size: 16px;
  font-weight: 600;
  color: #222222;
  line-height: 24px;
}
.moValueLine{
  width: 20px;
  height: 3px;
  background: #D70C0C;
  margin: 8px 0;
}
.moValueText{
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  line-height: 22px;
}
</style>
